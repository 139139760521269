<template>
  <div class="success-contaienr">
    <el-result style="margin-top: 100px" icon="success" :title="id ? '编辑成功' : '创建成功'">
      <template slot="extra">
        <el-button class="btn-blue" style="margin-top: 50px" @click="goto">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
    };
  },
  methods: {
    goto() {
      this.$router.push("/admin/dataAnalysis/manager");
    },
  },
};
</script>

<style lang="scss" scoped>
.success-contaienr {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
