<template>
  <el-scrollbar class="wrapper">
    <div class="createAnalysis-container">
      <step :activeIndex="activeIndex"></step>
      <keep-alive>
        <first v-if="activeIndex == 1" 
        @updateActiviteIndex="updateActiviteIndex" 
        @saveData="saveData" 
        @saveOldArr="saveOldArr">
        </first>
      </keep-alive>
      <keep-alive>
        <second v-if="activeIndex == 2" @updateActiviteIndex="updateActiviteIndex"></second>
      </keep-alive>
      <keep-alive>
        <third v-if="activeIndex == 3" @updateActiviteIndex="updateActiviteIndex"></third>
      </keep-alive>
      <keep-alive>
        <fourth v-if="activeIndex == 4" 
        @updateActiviteIndex="updateActiviteIndex" 
        @updateOldArr="updateOldArr" 
        :getData="getData" 
        :oldArr.sync="oldArr">
        </fourth>
      </keep-alive>
      <success v-if="activeIndex == 5"></success>
    </div>
  </el-scrollbar>
</template>

<script>
import step from "./module/step.vue";
import first from "./module/AnalysisFirst.vue";
import second from "./module/AnalysisSecond.vue";
import third from "./module/AnalysisThird.vue";
import fourth from "./module/AnalysisFourth.vue";
import success from "./module/AnalysisSuccess.vue";
export default {
  components: { step, first, second, third, fourth, success },
  data() {
    return {
      activeIndex: 1,
      getData: {},
      oldArr: [],
    };
  },
  methods: {
    updateActiviteIndex(data) {
      this.activeIndex = data;
    },
    saveData(data) {
      this.getData = data;
    },
    saveOldArr(data) {
      this.oldArr = data;
    },
    updateOldArr(data) {
      this.oldArr = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./module/Analysis";
.wrapper {
  @include showScrollbar;
}
.createAnalysis-container {
  width: 100%;
  height: 100%;
}
</style>
